









import { Vue, Component } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import { StepComponent } from '../../interfaces'
import StockSettings from '@/shared/components/StockSettings.vue'
import Account from '@/api/models/Account'
import Store from '@/api/models/Store'
import AccountSettings from '@/api/models/AccountSettings'
import StoresSettings from '@/api/models/StoresSettings'

@Component({
    components: {
        StockSettings
    }
})
export default class StockStep extends Vue implements StepComponent {
    $refs!: {
        stockComponent: StockSettings;
    }

    @Getter('getCurrentAccount')
    account!: Account

    @Action('updateAccountSettings')
    updateAccountSettings!: (settings: AccountSettings) => void

    @Action('updateStores')
    updateStores!: (stores: StoresSettings) => void

    private updateStock = false
    private storeMapping: Array<Store> = []

    public async nextStep (): Promise<boolean> {
        if (!this.$refs.stockComponent.validate()) {
            return Promise.resolve(false)
        }

        const settings = this.account.settings
        if (settings) {
            settings.updateStock = this.updateStock
            settings.isActive = true

            await this.updateAccountSettings(settings)
            if (this.storeMapping.length > 0) {
                await this.updateStores({ stores: this.storeMapping })
            }
        }

        return Promise.resolve(true)
    }
}
