







import { Vue, Component } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import PriceSettings from '@/shared/components/PriceSettings.vue'
import { StepComponent } from '@/modules/installer/interfaces'
import AccountSettings from '@/api/models/AccountSettings'
import Account from '@/api/models/Account'

@Component({
    components: {
        PriceSettings
    }
})
export default class PricesStep extends Vue implements StepComponent {
    $refs!: {
        pricesComponent: PriceSettings;
    }

    @Getter('getCurrentAccount')
    account!: Account

    @Action('updateAccountSettings')
    updateAccountSettings!: (settings: AccountSettings) => void

    private settings!: AccountSettings

    public async nextStep (): Promise<boolean> {
        if (!this.$refs.pricesComponent.validate()) {
            return Promise.resolve(false)
        }

        const accountSettings = this.account.settings
        if (accountSettings) {
            if (!this.settings) {
                accountSettings.updatePrice = false
            } else {
                accountSettings.updatePrice = this.settings.updatePrice
            }

            if (accountSettings.updatePrice) {
                accountSettings.priceType = this.settings.priceType
                accountSettings.oldPriceType = this.settings.oldPriceType
            }

            await this.updateAccountSettings(accountSettings)
        }

        return Promise.resolve(true)
    }

    private updateSettings (settings: AccountSettings) {
        this.settings = settings
    }
}
