





























import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
})
export default class Header extends Vue {
    @Prop({ type: Number, required: true })
    public currentStep!: number;

    @Prop({ type: Number, required: true })
    public countSteps!: number;

    get steps (): Array<number> {
        const steps: Array<number> = []

        for (let i = 1; i <= this.countSteps; i++) {
            steps.push(i)
        }

        return steps
    }

    private isSuccess (step: number): boolean {
        return this.currentStep > step
    }

    private isFuture (step: number): boolean {
        return this.currentStep < step
    }
}
