






























































import { Vue, Component, Prop } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import PriceType from '@/api/models/PriceType'
import { RootStateMutations } from '@/store/mutations'
import Connection from '@/api/models/Connection'
import { getBrandName } from '@/shared/components/helper'

export interface PriceTypes {
    oldPriceType?: string;
    priceType?: string;
}

@Component({})
export default class PriceSettings extends Vue {
    @Prop({ type: Boolean, default: false })
    readonly updatePriceProp!: boolean

    @Prop({ type: Object, default: () => { return {} } })
    readonly priceTypesProp!: PriceTypes

    @Getter('priceTypes')
    private readonly priceTypes!: Array<PriceType>

    @Getter('connection')
    connection!: Connection

    @Action('loadPriceTypes')
    loadPriceTypes!: () => Promise<void>

    private needUpdatePrice = false

    private oldPriceType: PriceType
    private priceType: PriceType

    private error = ''

    constructor () {
        super()

        this.oldPriceType = PriceSettings.emptyPriceType
        this.priceType = PriceSettings.emptyPriceType
    }

    get fullBrand (): string {
        return getBrandName(this.connection)
    }

    async created (): Promise<void> {
        this.$store.commit(RootStateMutations.setLoading, true)

        try {
            await this.loadPriceTypes()

            this.needUpdatePrice = this.updatePriceProp
            this.priceTypes.forEach((priceType: PriceType) => {
                if (priceType.code === this.priceTypesProp.oldPriceType) {
                    this.oldPriceType = priceType
                }

                if (priceType.code === this.priceTypesProp.priceType) {
                    this.priceType = priceType
                }
            })
        } finally {
            this.$store.commit(RootStateMutations.setLoading, false)
        }
    }

    private updatePrice (): void {
        this.error = ''

        this.$emit('updatePrices', {
            oldPriceType: this.oldPriceType.code ? this.oldPriceType.code : null,
            priceType: this.priceType.code ? this.priceType.code : null,
            updatePrice: this.needUpdatePrice
        })
    }

    public validate (): boolean {
        if (this.needUpdatePrice && !this.oldPriceType.code) {
            this.error = this.$t('settings.price.placeholder').toString()

            return false
        }

        return true
    }

    static get emptyPriceType (): PriceType {
        return { name: '', code: '' }
    }
}
