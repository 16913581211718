

























import { Component, Vue } from 'vue-property-decorator'
import Header from '@/modules/installer/components/Header.vue'
import StatusesStep from '../components/steps/StatusesStep.vue'
import PricesStep from '../components/steps/PricesStep.vue'
import StockStep from '../components/steps/StockStep.vue'
import { StepComponent } from '@/modules/installer/interfaces'
import BaseLayout from '@/shared/components/BaseLayout.vue'

@Component({
    components: {
        Header,
        BaseLayout,
        StatusesStep,
        PricesStep,
        StockStep
    }
})
export default class Stepper extends Vue {
    private step = 1

    private readonly steps: {[key: number]: string} = {
        1: 'StatusesStep',
        2: 'PricesStep',
        3: 'StockStep'
    }

    private get currentStep (): string {
        return this.steps[this.step]
    }

    get stepsCount (): number {
        return Object.keys(this.steps).length
    }

    get isLastStep (): boolean {
        return this.step === this.stepsCount
    }

    get continueButtonText (): string {
        if (this.isLastStep) {
            return this.$t('installer.completeInstall').toString()
        }

        return this.$t('connect.continue').toString()
    }

    private async next (): Promise<void> {
        const stepComponent: StepComponent = this.$refs.stepComponent as unknown as StepComponent

        try {
            const result = await stepComponent.nextStep()
            if (result) {
                if (this.isLastStep) {
                    await this.$router.push({ name: 'accounts' })
                } else {
                    this.step++
                }
            }
        } catch (e) {
            console.log(e)
        }
    }
}
