









import { Vue, Component } from 'vue-property-decorator'
import { StepComponent } from '../../interfaces'
import StatusesMapping from '@/shared/components/StatusesMapping.vue'
import Statuses from '@/api/models/Statuses'
import AccountService from '@/api/services/AccountService'
import { Getter } from 'vuex-class'
import Account from '@/api/models/Account'

const accountService = new AccountService()

@Component({
    components: {
        StatusesMapping
    }
})
export default class StatusesStep extends Vue implements StepComponent {
    $refs!: {
        statusesComponent: StatusesMapping;
    }

    private statuses!: Statuses

    @Getter('getCurrentAccount')
    account!: Account

    public update (statuses: Statuses): void {
        this.statuses = statuses
    }

    public async nextStep (): Promise<boolean> {
        return new Promise<boolean>((resolve): void => {
            if (!this.$refs.statusesComponent.validate()) {
                resolve(false)
                return
            }

            if (!this.account) {
                resolve(false)
                return
            }

            if (this.account.id) {
                accountService.updateStatuses(this.account.id, this.statuses)
                resolve(true)
                return
            }
            resolve(false)
        })
    }
}
